<template>
<div>
    <MyTechnicalList></MyTechnicalList>
</div>
</template>

<script>
// این ویو برای صفحه تحلیل های کاربر در پروفایل کاربران دیگر می باشد
import MyTechnicalList from '@/components/ProfileOthers/Technical.vue'

export default {
    name: 'ProfileOthersTechnicalView',
    components: {
        MyTechnicalList,
    },
    data: function () {
        return {
            widget: {},
            data: {},
        }
    },
}
</script>
